import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchIndicatorsData, postIndicatorsAreaData } from './../../services/geoplanetService';
import { Extent, Indicator, IndicatorArea, IndicatorsPostResponse } from '../../interfaces';

interface IndicatorsState {
    indicators: Indicator[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    selectedIndicatorAreaValue: IndicatorArea | null;
    selectedIndicatorValue: Indicator | null;
    indicatorArea: any
}

const initialState: IndicatorsState = {
    indicators: [],
    status: 'idle',
    error: null,
    selectedIndicatorAreaValue: null,
    selectedIndicatorValue: null,
    indicatorArea: ''
};


export const getIndicatorsData = createAsyncThunk('indicators/getIndicatorsData', async () => {
    const response = await fetchIndicatorsData();
    return response;
});

export const getIndicatorAreaData = createAsyncThunk(
    'indicators/getIndicatorAreaData',
    async ({ extent, exact_match,code }: { extent: Extent; exact_match: boolean;code:any }) => {
      const data = await postIndicatorsAreaData(extent, exact_match,code);
      return data;
    }
);


const indicatorsSlice = createSlice({
    name: 'indicators',
    initialState,
    reducers: {
        selectIndicator: (state, action) => {
            state.selectedIndicatorAreaValue = action.payload;
            state.selectedIndicatorValue = action.payload;
            state.status = 'succeeded';
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getIndicatorsData.pending, (state) => {
            if (state.status === 'idle') {
              state.status = 'loading';
            }
        })
        .addCase(getIndicatorsData.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.indicators = action.payload;
        })
        .addCase(getIndicatorsData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Failed to fetch indicators';
        })
        .addCase(getIndicatorAreaData.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getIndicatorAreaData.fulfilled, (state, action: PayloadAction<IndicatorsPostResponse>) => {
            state.status = 'succeeded';
            state.indicatorArea = action.payload;
        })
        .addCase(getIndicatorAreaData.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Failed to fetch case studies';
        });
    },
});

export const { selectIndicator } = indicatorsSlice.actions;

export default indicatorsSlice.reducer;
