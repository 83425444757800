import React from 'react';
import { Card, Row, Col, Divider, Space, Image, } from 'antd';
import { Species, SpeciesGeoPlanet } from '../../interfaces';
import {
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    UndoOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';

interface Props {
    filteredSpecies: (Species | SpeciesGeoPlanet)[],
    startIndex: number,
    endIndex: number
}

const SpeciesListTemplate:  React.FC<Props> = ({filteredSpecies, startIndex, endIndex}) => {
    return (
        <>
            {filteredSpecies.length > 0 &&
                <Divider />
            }            
            {filteredSpecies.length > 0 &&
                <Row gutter={16}>
                    {filteredSpecies.slice(startIndex, endIndex).map((species, index) => ( // Paginate filtered species
                        <Col span={24} key={`${species.name}-${index}`} style={{ marginBottom: 16 }}> {/* Column with bottom margin */}
                            <Card> {/* Card component */}
                                <Row gutter={16}> {/* Nested row for card content */}
                                    <Col span={12}> {/* Column for images */}
                                        <Row gutter={16}> {/* Nested row for images */}
                                            {species.images.slice(0, 3).map((image, index) => (
                                                <Col span={8} key={index}> {/* Column for each image */}
                                                    <Image
                                                        width={200}
                                                        src={image.s.includes('square') ? image.s.replace('square', 'medium') : image.s}
                                                        preview={{
                                                            toolbarRender: (
                                                                _,
                                                                {
                                                                    transform: { scale },
                                                                    actions: {
                                                                        onFlipY,
                                                                        onFlipX,
                                                                        onRotateLeft,
                                                                        onRotateRight,
                                                                        onZoomOut,
                                                                        onZoomIn,
                                                                        onReset,
                                                                    },
                                                                },
                                                            ) => (
                                                                <Space size={12} className="toolbar-wrapper">
                                                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                                                    <SwapOutlined onClick={onFlipX} />
                                                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                                                    <RotateRightOutlined onClick={onRotateRight} />
                                                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                                                    <UndoOutlined onClick={onReset} />
                                                                </Space>
                                                            ),
                                                        }}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Card title={species.name} extra={<span>Author: {species.author}</span>}>
                                            <p><strong>Family:</strong> {species.family}</p>
                                            <p><strong>Common Names:</strong> {species.commonNames}</p>
                                            <p><strong>Predicted Name:</strong> {species.predictedName}</p>
                                        </Card>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
            }                   
        </>
    );
};

export default SpeciesListTemplate;
