import React, { useEffect, useRef, useState } from 'react';
import { TileLayer, FeatureGroup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { EditControl } from 'react-leaflet-draw';
import L, { LatLngBounds } from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { fetchMinka, fetchSpeciesByArea } from '../../redux/slices/speciesSlice';
import "../../App.css";
import { Extent, Species } from '../../interfaces';
import { getIndicatorAreaData } from '../../redux/slices/indicatorsSlice';
import { getCenterAndZoom, generateLayer } from './MapFunctions';

const MapContent: React.FC<any> = ({ api, casestudy, flag, code }) => {
    const featureGroupRef = useRef<L.FeatureGroup>(null);
    const [filteredSpecies, setFilteredSpecies] = useState<Species[]>([]);
    const [caseStudy, setCaseStudy] = useState('');
    const [polygon, setPolygon] = useState<Extent>();
    const [apiName, setAPI] = useState('');
    const [minkaBounds, setMinkaBounds] = useState<LatLngBounds>();
    const [wmsLayers, setWmsLayers] = useState<JSX.Element[]>([]);
    const [wmsLayersHabitat, setWmsLayersHabitat] = useState<JSX.Element[]>([]);
    const map = useMap();
    const dispatch = useDispatch<AppDispatch>();
    const selectedCaseStudy = useSelector((state: RootState) => state.caseStudies.selectedCaseStudy);
    const selectedHabitats = useSelector((state: RootState) => state.habitat.selectedHabitats);
    const selectedIndicators = useSelector((state: RootState) => state.indicators.selectedIndicatorAreaValue);
    const minkaObjects = useSelector((state: RootState) => state.species.minkaSpecies);

    useEffect(() => {
        if (casestudy) {
            setCaseStudy(casestudy);
        }
        if (api) {
            setAPI(api);
        }

        console.log(minkaBounds,apiName,api,'sadas');

        if (polygon && apiName === 'geoplanet') {
            let extent: Extent = polygon;
            if (caseStudy !== '') {
                dispatch(fetchSpeciesByArea({ extent, exact_match: false, code: caseStudy }));
                setPolygon(undefined);
            }
        } else if (minkaBounds && apiName === 'minka'){
            dispatch(fetchMinka(minkaBounds));
            setFilteredSpecies(minkaObjects);   
            setMinkaBounds(undefined);    
        } else if (polygon && apiName === 'indic'){
            let extent: Extent = polygon;
            if (caseStudy !== '') {
                dispatch(getIndicatorAreaData({ extent, exact_match: false, code: caseStudy }));
                setPolygon(undefined);
            }
        }      
    }, [casestudy, api, apiName, polygon,minkaBounds,minkaObjects ,caseStudy]);


    useEffect(() => {
        const caseStudyCode = selectedCaseStudy?.code;
        const habitatCode = selectedHabitats?.code;
        const indicatorAlias = selectedIndicators?.alias;

        setCaseStudy(caseStudyCode ? caseStudyCode : '');
        const layers = [];

        if (caseStudyCode) {
            const { center, zoom } = getCenterAndZoom(caseStudyCode);
            map.setView(center, zoom);
            clearLayers();
            layers.push(generateLayer(caseStudyCode, `https://maps.plantnet.org/guarden/${caseStudyCode}/references?`, 'light_study_area', 999));

            if (flag === 'habitat' && habitatCode) {
                layers.push(generateLayer(`${caseStudyCode}_habitats_${habitatCode}`, `https://maps.plantnet.org/guarden/${caseStudyCode}/habitats?`, `habitat_${habitatCode}`, 1000));
            }

            if (flag === 'indicators' && indicatorAlias) {
                layers.push(generateLayer(`${caseStudyCode}_indicators_${indicatorAlias}`, `https://maps.plantnet.org/guarden/${caseStudyCode}/indicators?`, `${indicatorAlias}`, 1000));
            }
    
            setWmsLayers(layers);
        }
    }, [selectedCaseStudy?.code, selectedHabitats?.code, selectedIndicators?.alias, flag]);


    const handleCreated = async (e: any) => {
        const bounds: LatLngBounds = e.layer.getBounds();
        const boundsGeoplanet = e.layer.toGeoJSON().geometry;
        setPolygon(boundsGeoplanet);
        setMinkaBounds(bounds);

        if (featureGroupRef.current) {
            // Clear all layers except the current one
            const layers = featureGroupRef.current.getLayers();
            layers.forEach((layer) => {
                if (layer !== e.layer) {
                    featureGroupRef.current?.removeLayer(layer);
                }
            });
        }
        setAPI(api);
    };


    const handleDeleted = () => {
        setFilteredSpecies([]);
    };


    const clearLayers = () => {
        setWmsLayers([]);
        setWmsLayersHabitat([]);
    };


    return (
        <>
            <TileLayer
                noWrap={true}
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a>'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {selectedHabitats?.code && wmsLayersHabitat}

            {wmsLayers}

            {api === 'minka' && flag === 'species' && (
                <TileLayer
                    url="https://api.minka-sdg.org/v1/points/{z}/{x}/{y}.png"
                    zIndex={10}
                />
            )}

            <TileLayer
                noWrap={true}
                url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                maxZoom={20}
                subdomains={['mt1', 'mt2', 'mt3']}
                zIndex={10}
            />

            <FeatureGroup ref={featureGroupRef}>
                <EditControl
                    position="topright"
                    onCreated={handleCreated}
                    onDeleted={handleDeleted}
                    draw={{
                        rectangle: true,
                        polyline: false,
                        circle: true,
                        marker: false,
                        circlemarker: false,
                    }}
                />
            </FeatureGroup>
        </>
    );
};

export default MapContent;
