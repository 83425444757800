import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Extent, Species, SpeciesPostResponse } from '../../interfaces';
import { postSpeciesArea } from '../../services/geoplanetService';
import fetchObservations from '../../services/apiService';


interface SpeciesState {
    Species: Species[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    selectedSpecies: Species | null;
    geoPlanetSpecies: any;
    minkaSpecies:any;
}
  
const initialState: SpeciesState = {
    Species: [], 
    status: 'idle',
    error: null,
    selectedSpecies: null,
    geoPlanetSpecies: '',
    minkaSpecies: '',
}


export const fetchSpeciesByArea = createAsyncThunk(
    'species/fetchSpeciesByArea',
    async ({ extent, exact_match,code }: { extent: Extent; exact_match: boolean;code:string }) => {
        const data = await postSpeciesArea(extent, exact_match,code);
        return data;
    }
);


export const fetchMinka = createAsyncThunk(
    'species/fetchMinka',
    async ( bounds:any ) => {
        const data = await fetchObservations(bounds);
        return data;
    }
);


const SpeciesSlice = createSlice({
  name: 'Species',
  initialState,
  reducers: {
      selectSpecies(state, action: PayloadAction<Species>) {
        state.selectedSpecies = action.payload;
        state.status = 'succeeded';
      },
      setSpecies(state, action: PayloadAction<Species[]>) {
          state.Species = action.payload;
          state.status = 'succeeded';
      },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchSpeciesByArea.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchSpeciesByArea.fulfilled, (state, action: PayloadAction<SpeciesPostResponse>) => {
          state.status = 'succeeded';
          state.geoPlanetSpecies = action.payload;
        })
        .addCase(fetchSpeciesByArea.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to fetch case studies';
        })
        .addCase(fetchMinka.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchMinka.fulfilled, (state, action: PayloadAction<any>) => {
          state.status = 'succeeded';
          state.minkaSpecies = action.payload;
        })
        .addCase(fetchMinka.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message || 'Failed to fetch case studies';
        });
    },
});

export const { selectSpecies,setSpecies } = SpeciesSlice.actions;

export default SpeciesSlice.reducer;
