import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Input, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Species } from '../interfaces';
import SpeciesListTemplate from './templates/SpeciesListTemplate';

interface Props {
    speciesData: Species[];
}

const SpeciesListMinka: React.FC<Props> = ({ speciesData }) => {
    const [filteredSpecies, setFilteredSpecies] = useState<Species[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const pageSize = 6; // Number of species per page

    // Handler for search input change
    const handleSearch = (value: string) => {
        setSearchText(value.toLowerCase());
    };

    // Effect to filter species based on search text whenever speciesData or searchText changes
    useEffect(() => {
        if (speciesData) {
            filterSpecies(searchText);
        }
    }, [speciesData, searchText]);

    // Function to filter species based on search text
    const filterSpecies = (search: string) => {
        const filtered = speciesData.filter(species =>
            (species.name && species.name.toLowerCase().includes(search)) ||
            (species.author && species.author.toLowerCase().includes(search)) ||
            (species.family && species.family.toLowerCase().includes(search)) ||
            (species.commonNames && species.commonNames.toLowerCase().includes(search)) ||
            (species.predictedName && species.predictedName.toLowerCase().includes(search))
        );
        setFilteredSpecies(filtered); // Update filtered species state
    };

    // Handler for pagination change
    const handlePageChange = (page: number) => {
        setCurrentPage(page); // Update current page
    };

    // Calculate start and end index for current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    return (
        <div>
            <Row gutter={16}>
                {filteredSpecies.length > 0 &&
                    <Col span={12}>
                        <Card style={{ marginBottom: 16, marginTop: 16 }}>
                            <Input
                                placeholder="Search species"
                                prefix={<SearchOutlined />}
                                style={{ width: 300, marginBottom: 0 }}
                                onChange={(e) => handleSearch(e.target.value)} // Call handleSearch on input change
                            />
                        </Card>
                    </Col>
                }
            </Row>
            <SpeciesListTemplate filteredSpecies={filteredSpecies} startIndex={startIndex} endIndex={endIndex}/>
            {filteredSpecies.length > 0 &&
                <Pagination
                    current={currentPage}
                    total={filteredSpecies.length} // Total number of species for pagination
                    pageSize={pageSize}
                    onChange={handlePageChange} // Call handlePageChange on page change
                    style={{ marginTop: 20, textAlign: 'center' }}
                />
            }
        </div>
    );
};

export default SpeciesListMinka;
