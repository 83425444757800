import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { fetchHabitats,  } from './../../services/geoplanetService';
import { Habitat, Habitats } from '../../interfaces';


interface HabitatsState {
    habitats: Habitats | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    selectedHabitats: Habitat | null; 
}
  
const initialState: HabitatsState = {
    habitats: null, 
    status: 'idle',
    error: null,
    selectedHabitats: null,
};


export const getHabitats = createAsyncThunk('habitats/getHabitats', async (flag:string) => {
    const data = await fetchHabitats(flag);
    return data;
});


const HabitatsSlice = createSlice({
    name: 'Habitats',
    initialState,
    reducers: {
        selectHabitats(state, action: PayloadAction<Habitat>) {
            state.selectedHabitats = action.payload;
        },
        setHabitats(state, action: PayloadAction<Habitats>) {
            state.habitats = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getHabitats.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getHabitats.fulfilled, (state, action: PayloadAction<Habitats>) => {
            state.status = 'succeeded';
            state.habitats = action.payload;
        })
        .addCase(getHabitats.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message || 'Failed to fetch case studies';
        });
    },
});

export const { selectHabitats,setHabitats } = HabitatsSlice.actions;

export default HabitatsSlice.reducer;
