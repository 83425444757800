import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import speciesReducer from './slices/speciesSlice';
import habitatReducer from './slices/habitatSlice';
import caseStudiesReducer from './slices/caseStudiesSlice';
import indicatorsReducer from './slices/indicatorsSlice';

export const store = configureStore({
    reducer: {
        species: speciesReducer,
        habitat: habitatReducer,
        caseStudies: caseStudiesReducer,
        indicators: indicatorsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
