import React from 'react';
import IndicatorsLayout from '../layouts/IndicatorsLayout';


const IndicatorsConservation: React.FC = () => {
    return (
        <IndicatorsLayout flag={"indicators"} api={'indic'}/>
    );
};

export default IndicatorsConservation;
