import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../redux/store';
import { getCaseStudies, selectCaseStudy } from '../redux/slices/caseStudiesSlice';
import { Select } from 'antd';

const { Option } = Select;

const CaseStudiesDropdown: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const caseStudiesData = useSelector((state: RootState) => state.caseStudies.data);
    const caseStudiesStatus = useSelector((state: RootState) => state.caseStudies.status);
    const selectedCaseStudy = useSelector((state: RootState) => state.caseStudies.selectedCaseStudy);

    useEffect(() => {
      if (caseStudiesStatus === 'idle') {
        dispatch(getCaseStudies());

      }
    }, [caseStudiesStatus, dispatch]);


    const handleSelectChange = (value: string) => {      
        const selected = caseStudiesData.find(caseStudy => caseStudy.code === value);

        if (selected) {
          dispatch(selectCaseStudy(selected));
        }
        console.log('Yeah Case Study',caseStudiesData, caseStudiesStatus, selectedCaseStudy);
    };


    return (
        <div style={{padding: 0,paddingLeft: 15}}>
            <Select
                placeholder="Select a Case Study"
                loading={caseStudiesStatus === 'loading'}
                onChange={handleSelectChange}
                value={selectedCaseStudy ? selectedCaseStudy.code : ''}
                style={{ width: 200 }}
            >
                <Option value="">Select a Case Study</Option>
                {caseStudiesData.map(caseStudy => (
                    <Option key={caseStudy.code} value={caseStudy.code}>{caseStudy.name}</Option>
                ))}
            </Select>
        </div>
    );
};

export default CaseStudiesDropdown;
