import { WMSTileLayer } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';

export const getCenterAndZoom = (caseStudyCode: string | undefined): { center: LatLngTuple, zoom: number } => {
    switch (caseStudyCode?.toLowerCase()) {
        case 'cyprus':
            return { center: [34.756757, 33.316817], zoom: 12 }; // Limassol, Cyprus
        case 'greece':
            return { center: [37.9838, 23.7275], zoom: 6.3 }; // Athens, Greece
        case 'barcelona':
            return { center: [41.3851, 2.1734], zoom: 10 }; // Barcelona, Spain
        case 'pnr_narbonnaise':
            return { center: [43.024819, 2.970399], zoom: 10 }; // pnr_narbonnaise, Spain
        case 'cbnmed':
            return { center: [43.567800, 3.438540], zoom: 6.3 }; // pnr_narbonnaise, Spain
        default:
            return { center: [34.7071, 33.0226], zoom: 3 }; // Default position
    }
};


export const generateLayer = (code: string, url: string, layers: string, zIndex: number) => (
    <WMSTileLayer
        key={`${code}`}
        url={`${url}`}
        layers={`${layers}`}
        format="image/png"
        transparent={true}
        version="1.1.1"
        attribution="Your attribution text"
        zIndex={zIndex}
    />
);