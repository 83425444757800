import React from 'react';
import SpeciesLayout from '../layouts/SpeciesLayout';


const SpeciesPredictions: React.FC = () => {
    return (
        <SpeciesLayout flag={"species"} api={'geoplanet'}/>
    );
};

export default SpeciesPredictions;
