import React from 'react';
import { MapContainer } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import MapContent from './map/MapContent';

const MapComponent:  React.FC<any> = ({ api, flag, code }) => {
    const defaultPosition: LatLngTuple = [51.505, -0.09]; // Default map position

    return (
        <MapContainer className="map" center={defaultPosition} zoom={3} style={{ height: '650px', width: '100%' }}>                        
            <MapContent api={api} flag={flag} code={code} />
        </MapContainer>
    );
};

export default MapComponent;
