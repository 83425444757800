import React from 'react';
import { Card, Row, Col } from 'antd';
import species from '../../assets/species.png';
import habitat from '../../assets/habitat.png';
import conservation from '../../assets/conservation.png';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                <Link to={'/species/observations'}>

                        <Card style={{
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat", backgroundImage: `url(${species
                                })`, height: 350
                        }}
                            hoverable
                        // cover={<img alt="example"  src={species}/>}
                        >
                            <h1 style={{
                            color: 'white',fontWeight: 100
                        }}>Species</h1>

                        </Card>
                    </Link>
                </Col>
                <Col span={12}>
                    <Link to={'habitat'}>
  
                    <Card style={{
                        backgroundColor:"none",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat", backgroundImage: `url(${habitat
                                })`, height: 350
                        }}
                            hoverable
                        // cover={<img alt="example"  src={species}/>}
                        >
                            <h1 style={{
                            color: 'white',fontWeight: 100
                        }}>Habitats</h1>
                        </Card></Link>
                </Col>
                <Col span={12}>
                    <Link to={'/indicators/conservation'}>
  
                    <Card style={{
                        backgroundColor:"none",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat", backgroundImage: `url(${conservation
                                })`, height: 350
                        }}
                            hoverable
                        // cover={<img alt="example"  src={species}/>}
                        >
                            <h1 style={{
                            color: 'white',fontWeight: 100
                        }}>Indicators</h1>
                        </Card></Link>
                </Col>
            </Row>
        </div>
    );
};

export default Home;
