import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './components/pages/Home';
import SpeciesObservations from './components/pages/SpeciesObservations';
import SpeciesPredictions from './components/pages/SpeciesPredictions';

import Habitat from './components/pages/Habitat';
import IndicatorsConservation from './components/pages/IndicatorsConservation';

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/species/observations" element={<SpeciesObservations />} />
        <Route path="/species/predictions" element={<SpeciesPredictions />} />
        <Route path="/indicators/conservation" element={<IndicatorsConservation />} />
        <Route path="/habitat" element={<Habitat />} />
    </Routes>
);

export default AppRoutes;
