import React from 'react';
import SpeciesLayout from '../layouts/SpeciesLayout';


const SpeciesObservations: React.FC = () => {
    return (
        <SpeciesLayout flag={"species"} api={'minka'}/>
    );
};

export default SpeciesObservations;
