import React from 'react';
import { Layout } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './components/shared/Sidebar';
import AppHeader from './components/shared/Header';
import AppContent from './components/shared/Content';
import Routes from './routes';
import { ConfigProvider, theme } from 'antd';

const { defaultAlgorithm, compactAlgorithm, darkAlgorithm } = theme;

const App: React.FC = () => (
        <ConfigProvider
            theme={{
                algorithm: compactAlgorithm,
                token: {
                    "colorPrimary": "#0e0e0e",
                    "fontSize": 16,
                    "borderRadius": 14
                }
            }}
        >
            <Router>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sidebar />
                    <Layout className="site-layout">
                        <AppHeader />
                        <AppContent>
                            <Routes />
                        </AppContent>
                    </Layout>
                </Layout>
            </Router></ConfigProvider>

);

export default App;
