import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import SpeciesListMinka from '../SpeciesListMinkaComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import MapComponent from '../MapComponent';
import SpeciesListGeoPlanet from '../SpeciesListGeoPlanet';

const SpeciesLayout: React.FC<any> = ({ flag,api }) => {
    const [apiTemp, setApi] = useState<string>('');
    const caseStudiesData = useSelector((state: RootState) => state.caseStudies.data);
    const selectedSpecies = useSelector((state: RootState) => state.species.selectedSpecies);
    const species = useSelector((state: RootState) => state.species.minkaSpecies);
    const speciesGeoplanet = useSelector((state: RootState) => state.species.geoPlanetSpecies);

    useEffect(() => {            
        console.log(species, selectedSpecies, caseStudiesData, 'yes',api,apiTemp,'sadas');

        if (api) {
            setApi(api);

        } 
        console.log(species, selectedSpecies, caseStudiesData, 'map');

    }, [api, apiTemp, species, speciesGeoplanet,caseStudiesData, selectedSpecies]);
    
    return (
        <div>
            <Row gutter={16}>
                <Col span={24}>
                    <Card>
                        <MapComponent api={apiTemp} flag={flag} />
                    </Card>
                </Col>
                <Col span={24}>
                {apiTemp === 'minka' &&
                    <SpeciesListMinka speciesData={species} />}
                                    {apiTemp === 'geoplanet' &&
                    <SpeciesListGeoPlanet speciesData={speciesGeoplanet.species} />}
                </Col>
            </Row>
        </div>
    );
};

export default SpeciesLayout;
