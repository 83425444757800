import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Select, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { getHabitats, selectHabitats } from '../../redux/slices/habitatSlice';
import { Habitat } from '../../interfaces';
import MapComponent from '../MapComponent';
const { Option } = Select;

const HabitatLayout: React.FC<any> = ({ flag }) => {
    const api = 'minka';
    const dispatch = useDispatch<AppDispatch>();
    const [code, setCode] = useState<any>('');
    const [useHabitatDropdown, setUseHabitatDropdown] = useState<Habitat>();

    const selectedCaseStudy = useSelector((state: RootState) => state.caseStudies.selectedCaseStudy);
    const habitatData = useSelector((state: RootState) => state.habitat.habitats);
    const habitatStatus = useSelector((state: RootState) => state.habitat.status);

    useEffect(() => {
        console.log(habitatStatus);
        if (selectedCaseStudy?.code != null && habitatStatus === 'idle') {
            setCode(selectedCaseStudy?.code);
            console.log(selectedCaseStudy?.code, code, 'mesa');
            var temp = selectedCaseStudy?.code;
            dispatch(getHabitats(temp!));
        }
        console.log(habitatData, habitatStatus, code);
    }, [habitatData, selectedCaseStudy, habitatStatus, code, dispatch]);
    

    const handleChangeUseCase = (value: any) => {
        const selected = habitatData?.habitats.find(caseStudy => caseStudy.code === value);
        if (selected) {
            dispatch(selectHabitats(selected));
            setUseHabitatDropdown(selected);
        }
        console.log(selected, useHabitatDropdown, 'click');
    };

    return (
        <div>
            <Row gutter={16}>
                <Col span={6}>
                    <Card title="Information">
                        <Select
                            defaultValue=""
                            style={{ width: '100%' }}
                            onChange={handleChangeUseCase}
                            loading={habitatStatus === 'loading'}
                            value={useHabitatDropdown ? useHabitatDropdown.code : ''}
                        >
                            {/* <Option value="">Please Select a Case Study</Option> */}
                            {habitatData && habitatData?.habitats.map(caseStudy => (
                                <Option key={caseStudy.id} value={caseStudy.code}>{caseStudy.code} {caseStudy.name}</Option>
                            ))}
                        </Select>
                        {useHabitatDropdown &&
                            <div key={useHabitatDropdown?.code} style={{ width: '100%' }}>
                                <h3>     <Tag >{useHabitatDropdown?.code}</Tag>
                                    <strong>{useHabitatDropdown?.name} </strong> </h3>
                                <p><strong>Level:</strong> {useHabitatDropdown?.level}</p>
                            </div>
                        }
                    </Card>

                </Col>
                <Col span={18}>
                    <Card>
                        <MapComponent api={api} flag={flag} code={code} />
                    </Card>
                </Col>

            </Row>
        </div>
    );
};

export default HabitatLayout;
