import React from 'react';
import HabitatLayout from '../layouts/HabitatLayout';


const Habitat: React.FC = () => {
    return (
        <HabitatLayout flag={"habitat"}/>
    );
};

export default Habitat;
