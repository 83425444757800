import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Row, Col, Card } from 'antd';
import { Indicator, IndicatorArea } from '../interfaces';
import GaugeChartComponent from './GaugeChartComponent';

interface Props {
    indicatorsData: Indicator[]
    indicatorAreaData: IndicatorArea[];
    selectedIndicProp: Indicator | null;
}

const GaugeListComponent:  React.FC<Props> = ({indicatorsData, indicatorAreaData, selectedIndicProp}) => {
    const [filteredIndicatorAreaData, setFilteredIndicatorAreaData] = useState<IndicatorArea[]>([]);
    const selectedIndicator = useSelector((state: RootState) => state.indicators.selectedIndicatorAreaValue);


    useEffect(() => {
        if (selectedIndicProp != null) {
            const data = indicatorAreaData.filter(indicator => indicator.alias === selectedIndicProp.alias);
            setFilteredIndicatorAreaData(data);
        } else {
            setFilteredIndicatorAreaData(indicatorAreaData);
        }
    }, [indicatorAreaData,selectedIndicProp, selectedIndicator]);


    //Merge keys name and alias in one array
    const mergedIndicatorsArray = filteredIndicatorAreaData.map(area => {
        const indicator = indicatorsData.find(indicator => indicator.alias === area.alias);
        return {...area, ...indicator};
    });
    console.log(mergedIndicatorsArray);


    return (
        <Row gutter={[8, 32]}>
            {mergedIndicatorsArray.map(indicator => (
                <Col key={indicator.alias} span={24} sm={24} md={12} lg={8} xl={6}>              
                    <Card title={indicator.name}>
                                            <GaugeChartComponent indicatorDataProp={indicator}/>

                    </Card>
                </Col>                       
            ))}           
        </Row>
    );
};

export default GaugeListComponent;
