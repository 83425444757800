import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import MapComponent from '../MapComponent';
import GaugeListComponent from '../GaugeListComponent';

const IndicatorsLayout: React.FC<any> = ({ flag,api }) => {
    const [apiTemp, setApi] = useState<string>('');

    const indicatorsData = useSelector((state: RootState) => state.indicators.indicators); //Fetch alias & name
    const indicatorAreaData = useSelector((state: RootState) => state.indicators.indicatorArea); //Fetch min,max,mean values
    const indicatorStatus = useSelector((state: RootState) => state.indicators.status);
    const selectedIndicatorValue = useSelector((state: RootState) => state.indicators.selectedIndicatorValue);


    useEffect(() => {            
        if (api) {
            setApi(api);
        }      
        console.log(selectedIndicatorValue);
    }, [api, indicatorAreaData, selectedIndicatorValue]);
    
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card>
                    <MapComponent api={apiTemp} flag={flag} />
                </Card>
            </Col>
            <Col span={24}>
                {indicatorStatus === 'succeeded' && indicatorAreaData?.indicators ? (
                    selectedIndicatorValue ? (
                        <GaugeListComponent indicatorsData={indicatorsData} indicatorAreaData={indicatorAreaData.indicators} selectedIndicProp={selectedIndicatorValue} />
                    ) : (
                        <GaugeListComponent indicatorsData={indicatorsData} indicatorAreaData={indicatorAreaData.indicators} selectedIndicProp={null}/>
                    )
                ) : (
                    <></>
                )}
            </Col>
        </Row>
    );
};

export default IndicatorsLayout;
