import React from 'react';
import GaugeComponent from 'react-gauge-component';
import { IndicatorArea } from '../interfaces';

interface Props {
    indicatorDataProp: IndicatorArea
}

const GaugeChartComponent:  React.FC<Props> = ({indicatorDataProp}) => {
    return (
        <GaugeComponent 
            minValue={indicatorDataProp.min} 
            maxValue={indicatorDataProp.max} 
            value={indicatorDataProp.mean} 
            className='gauge-wrapper'
            arc={{
                nbSubArcs: 30,
                colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                width: 0.3,
                padding: 0.003,
                subArcs: [
                    {
                        limit: indicatorDataProp.mean,
                        color: '#5c58586c'
                    }
                ]
            }}                           
            labels={
                {
                    tickLabels: {
                        defaultTickValueConfig: {
                            style: {
                                fontSize: 14,
                                fill: "#5c58586c"
                            }
                        }
                    },
                    valueLabel: {
                        style: {
                            fontSize: 32,
                            fill: '#5C5C5C'
                        }
                    }
                }
            }   
        />           
    );
};

export default GaugeChartComponent;
