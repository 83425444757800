import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../redux/store';
import { getIndicatorsData, selectIndicator } from '../redux/slices/indicatorsSlice';
import { Select, Spin } from 'antd';

const { Option } = Select;

const IndicatorsDropdown: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const indicatorsData = useSelector((state: RootState) => state.indicators.indicators);
  const indicatorsStatus = useSelector((state: RootState) => state.indicators.status);
  const selectedIndicatorAreaValue = useSelector((state: RootState) => state.indicators.selectedIndicatorAreaValue);


  useEffect(() => {
    if (indicatorsStatus === 'idle') {
      dispatch(getIndicatorsData());
    }
  }, [indicatorsStatus, dispatch]);
  

  const handleSelectChange = (value: string) => {
      if (value === "") {
          dispatch(selectIndicator(null));
      } else {
          const selectedValue = indicatorsData.find(indicator => indicator.alias === value);

          if (selectedValue) {
            dispatch(selectIndicator(selectedValue));
          }
      }

      console.log(indicatorsData, indicatorsStatus, selectedIndicatorAreaValue);
  };


  if (indicatorsStatus === 'loading') {
      return <Spin />;
  }


  return (
    <div style={{ padding: 0, paddingLeft: 15 }}>
        <Select
            placeholder="Select an Indicator"
            onChange={handleSelectChange}
            value={selectedIndicatorAreaValue ? selectedIndicatorAreaValue.alias : ""}
            style={{ width: 200 }}
        >
            <Option value="">Select Indicator</Option>
            {indicatorsData.map(indicator => (
                <Option key={indicator.alias} value={indicator.alias}>
                  {indicator.name}
                </Option>
            ))}
        </Select>
    </div>
  );
};

export default IndicatorsDropdown;
